<template>
  <SumHeaderTable
    :value="keywords"
    :global-filter-fields="['keywordText']"
    :columns="columns"
    :sums="sums"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import SumHeaderTable from '../../../../SumHeaderTable.vue';
import generateCommonColumns from '../../../../../utils/tableUtils';
import { formatBid, formatMatchType } from '../../../../../utils/formatUtils';

export default {
  components: { SumHeaderTable },
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    selectedProfile: {
      type: Object,
      required: true,
    },
    keywords: {
      type: Array,
      required: true,
    },
    sums: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        {
          id: 'keywordText',
          field: 'keywordText',
          header: 'Keyword',
          sortable: true,
          numeric: false,
          decimal: false,
          width: '300px',
          content: {
            center: false,
            to: null,
            badge: null,
            format: null,
          },
        },
        {
          id: 'keywordState',
          field: 'state',
          header: 'Status',
          sortable: true,
          numeric: false,
          decimal: false,
          width: '100px',
          content: {
            center: false,
            to: null,
            badge: true,
            format: null,
          },
        },
        {
          id: 'keywordMatchType',
          field: 'matchType',
          header: 'Match Type',
          sortable: true,
          numeric: false,
          decimal: false,
          width: '120px',
          content: {
            center: true,
            to: null,
            badge: null,
            format: (data) => formatMatchType(data),
          },
        },
        {
          id: 'keywordBid',
          field: 'bid',
          header: 'Bid',
          sortable: true,
          numeric: false,
          decimal: true,
          width: '80px',
          content: {
            center: true,
            to: null,
            remove: '$',
            badge: null,
            format: (data) => formatBid(data, this.$store.state.auth.selectedProfile.currencyCode),
          },
        },
        ...generateCommonColumns('keywords', () => this.royalties, () => this.$store.state.auth.selectedProfile),
      ],
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },
};
</script>

<style scoped lang="scss">
@import "../../../../../assets/styles/dashboard/campaigns.scss";
</style>
